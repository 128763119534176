import styled, { css } from 'styled-components';

const splitBorders = css`
  border-top: 5px solid ${props => props.theme.colors.claytonGreen};
`

const CTACardStyles = styled.section`
  position: relative;
  padding: ${props =>
    props.condensed
      ? `0 ${props.theme.padding.inner}`
      : `0 ${props.theme.padding.inner} 1.75rem`};
  margin-bottom: -1.5rem;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    height: ${props =>
      props.condensed ? 'calc(100% - 3.75rem)' : '5.1875rem'};
    width: 100%;
    background: ${props => props.backgroundTop || props.theme.colors.white};
    z-index: -1;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    height: ${props =>
      props.condensed ? '3.75rem' : 'calc(100% - 5.1875rem)'};
    width: 100%;
    background: ${props =>
      props.backgroundBottom || props.theme.colors.darkBlue};
    z-index: -1;
    ${props => props.borders ? splitBorders : ''};
  }

  .inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: ${props =>
      props.condensed
        ? '3.0625rem 1.625rem 3.375rem'
        : '2.7rem 2.3125rem 2.3rem'};
    max-width: ${props => props.theme.maxWidths.containerSmall};
    margin: 0 auto 1.5rem;
    box-shadow: ${props => (props.flat ? 'none' : props.theme.shadows.card)};
    background: ${props =>
      props.foreground || props.theme.colors.lightBlueTint};
    border-radius: ${props => props.theme.borderRadiuses.card};

    @media (prefers-reduced-motion: no-preference) {
      .isAnimated& {
        opacity: 0;
        transform: scale(1.1);
        transition: all 0.4s ease-in-out;
        overflow: hidden;
      }
    }

    .active& {
      opacity: 1;
      transform: scale(1);
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: ${props =>
        props.condensed
          ? '3.75rem 3.125rem 4.5625rem'
          : props.divider
          ? '4.4rem 3.4375rem 4.25rem 6.3175rem'
          : '4.4rem 6.3175rem 4.25rem'};
      max-width: ${props =>
        props.condensed
          ? props.theme.maxWidths.ctaCardCondensed
          : props.theme.maxWidths.ctaCard};
    }

    .primary {
      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        margin-bottom: 2rem;
        padding-top: 2rem;
      }

      a {
        color: ${props => props.theme.colors.darkClaytonBlue};

        &:hover, &:active {
          color: ${props => props.theme.colors.bigSky};
        }
      }

      h3 {
        color: ${props => props.theme.colors.claytonBlue};
      }

    }

    .primary,
    .secondary {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 0 0 auto;
      text-align: ${props => (props.condensed ? 'left' : 'center')};
      box-sizing: border-box;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        align-items: stretch;
        text-align: left;
      }
    }

    .primary {
      flex: 1 1 auto;

      * {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      h2 {
        margin-top: 0;
        font-size: ${props => props.theme.type.h2mobile};
        line-height: ${props => props.theme.lineHeights.h2mobile};

        @media (min-width: ${props => props.theme.breakpoints.tablet}) {
          font-size: ${props => props.theme.type.h2};
          line-height: ${props => props.theme.lineHeights.h2};
        }

        @media (min-width: ${props => props.theme.breakpoints.desktop}) {
          font-size: ${props => props.theme.type.h1};
          line-height: ${props => props.theme.lineHeights.h1};
        }
      }

      .type-h1 {
        margin: 0;
      }

      h4 {
        margin-bottom: 0;
      }

      p {
        margin-bottom: 10px;

        &:first-child {
          text-transform: uppercase;
        }
      }

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        padding-right: 1rem;
        max-width: ${props => (props.condensed ? 'none' : '654px')};
        width: ${props => (props.divider ? '453px' : '50%')};
        width: 20%;
      }

     

      .block-cta_card--simple& {
        width: 100%;
        align-items: center;
        margin: auto;

        p {
          text-transform: initial;
        }
      }
    }

    .secondary {
      padding-top: 1rem;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        width: ${props => (props.divider ? '410px' : '50%')};
        padding-left: 3.9375rem;
        margin-right: ${props => (props.divider ? '3rem' : '0')};
        padding-top: 0;
        max-width: ${props => (props.condensed ? '300px' : '450px')};
        border-left: ${props =>
          props.divider ? `solid 1px ${props.theme.colors.vmfBlue}` : ''};
      }   

      .button-link {
        line-height: 1.1em;
      }
    }

    .disclaimer {
      width: 100%;
      margin-top: 0.5rem;
      text-align: center;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        text-align: left;
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      justify-content: ${props => (props.condensed ? 'flex-start' : 'center')};

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        justify-content: flex-start;
      }

      a {
        line-height: 1.5em;

        &:nth-child(2) {
          margin-left: 1rem;
        }
      }
    }

    .note {
      margin: 1rem 0 0;

      p {
        font-size: ${props => props.theme.type.paragraph.intro};
        line-height: ${props => props.theme.lineHeights.paragraph.intro};
        letter-spacing: ${props => props.theme.letterSpacing.paragraph};
        margin: 0;
        @media (max-width: ${props => props.theme.breakpoints.tablet}) {
          display: flex;
          flex-direction: column;
        }
      }   

      a {
        margin-top: 0.25rem;
        display: inline-block;
        text-decoration: none;
        
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: ${props =>
      props.condensed
        ? `0 ${props.theme.padding.inner}`
        : `0 ${props.theme.padding.inner} 4.5625rem`};
  }
`;

export default CTACardStyles;