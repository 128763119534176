import styled from 'styled-components';

const CategoryStyles = styled.p`
  color: ${props => props.theme.colors.claytonBlue};
  align-items: center;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: ${props => props.theme.type.label.small};
  line-height: ${props => props.theme.lineHeights.label.small};
  font-weight: 600;
  z-index: 10;
  position: relative;

  svg {
    fill: ${props => props.theme.colors.vmfBlue};
    height: 1.2em;
    width: 1.2em;
    margin-right: 0.25rem;
    margin-top: -3px;
  }

  span {
    margin-left: 0.25rem;
  }
`;

export default CategoryStyles;
