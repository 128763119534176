import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import LogoGridStyles from './LogoGrid.styled';
import Picture from '../Shared/Picture';

const defaultLogos = [
  {
    src: 'https://images.ctfassets.net/y64yks2od8ab/5zTiPJ5UvvEOBCu0x4EclS/0bea17868b58eac226126e43179c72cf/claytonHomes.png?w=200',
    alt: 'Clayton Homes Logo',
    width: '200',
    height: '122',
  },
  {
    src: 'https://images.ctfassets.net/y64yks2od8ab/1zZST0UCvP2BedmZnP1TIC/95c271809eadab2750d122d1349502cd/crosslandHomes.png?w=200',
    alt: 'Crossland Homes Logo',
    width: '200',
    height: '122',
  },
  {
    src: 'https://images.ctfassets.net/y64yks2od8ab/7x2ywbtr5UuDfh2FTL8NgK/a6d67188c3339aa3ceaa0704b506bafd/freedomHomes.png?w=200',
    alt: 'Freedom Homes Logo',
    width: '200',
    height: '122',
  },
  {
    src: 'https://images.ctfassets.net/y64yks2od8ab/6oTUhCeiSP7EJRvhQzf6ig/196e64df4a3c3e792adec383adcfc4a5/internationalHomes.png?w=200',
    alt: 'International Homes Logo',
    width: '200',
    height: '122',
  },
  {
    src: 'https://images.ctfassets.net/y64yks2od8ab/3RniscgxLb9wAjlC7Ycs1T/fbaf08c4c22213a044323ac0c90d0d60/luvHomes.png?w=200',
    alt: 'Luv Homes Logo',
    width: '200',
    height: '122',
  },
  {
    src: 'https://images.ctfassets.net/y64yks2od8ab/3jPHlMGisZACk5qvSzOnM8/d5286f8c9bbc8e457c7c3cf474a8b206/oakwoodHomes.png?w=200',
    alt: 'Oakwood Homes Logo',
    width: '200',
    height: '122',
  },
  {
    src: 'https://images.ctfassets.net/y64yks2od8ab/2Cz5jvEsSQn6v6VkWlEti1/67baa7520461c5cb98ecfb43f0091cfa/truValue.png?w=200',
    alt: 'TruValue homes Logo',
    width: '200',
    height: '122',
  },
];

const LogoGrid = ({
  title = 'Our Home Partners',
  logos = defaultLogos,
  isAnimated = true,
  background,
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });

  const claytonLogo = logos.find(
    ({ src = '', alt = '', width = '', height = '' }) =>
      alt === 'Clayton Homes Logo'
  );

  const logoList = logos.filter(
    ({ src = '', alt = '', width = '', height = '' }) =>
      alt !== 'Clayton Homes Logo'
  );

  return (
    <LogoGridStyles
      ref={ref}
      background={background}
      className={`block-logo_grid ${isAnimated ? 'isAnimated' : ''} ${
        inView ? 'active' : ''
      }`}
    >
      <div className='inner'>
        {title && <h2>{title}</h2>}
        <div
          style={{ animationDelay: `${0.1}s` }}
          className='claytonLogoContainer'
        >
          <a
            href='/find-a-home/'
            target='_blank'
            rel='noopener noreferrer'
            className='claytonLogo'
          >
            <Picture
              width={claytonLogo.width}
              height={claytonLogo.height}
              src={claytonLogo.src}
              alt={claytonLogo.alt}
            />
          </a>
        </div>
        <ul className='logos'>
          {logoList.map(
            ({ src = '', alt = '', width = '', height = '' }, i) => (
              <li key={i} style={{ animationDelay: `${i * 0.1}s` }}>
                <a
                  href='/find-a-home/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Picture width={width} height={height} src={src} alt={alt} />
                </a>
              </li>
            )
          )}
        </ul>
      </div>
    </LogoGridStyles>
  );
};

LogoGrid.propTypes = {
  title: PropTypes.string,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ),
  background: PropTypes.string,
  isAnimated: PropTypes.bool,
};

export default LogoGrid;
