import styled from 'styled-components';

const PostStyles = styled.article`
  display: flex;
  flex-direction: column;
  position: relative;

  &.post--modal_open {
    z-index: 10000;
  }

  .thumbnail {
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 65.3%;
    background: ${props => props.theme.colors.grey6};

    @media (max-width: ${props => props.theme.breakpoints.desktop}) {
      display: none;
    }

    &--darker {
      &::after {
        display: block;
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: ${props => props.theme.colors.black};
        opacity: 0.15;
        z-index: 1;
      }
    }

    picture {
      width: 100%;
      height: 100%;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    svg {
      position: absolute;
      top: 57%;
      left: 50%;
      width: 88px;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  .modal {
    .content {
      overflow: hidden;
    }
  }

  p {
    margin: 0;
  }

  .title {
    margin: 0.4375rem 0 0;
    line-height: 1.3125em;

    a {
      text-decoration: none;
      color: ${props => props.theme.colors.grey1};

      &:hover,
      &:active,
      &:focus {
        color: ${props => props.theme.colors.claytonBlue};
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 9;
      }
    }
  }
`;

export default PostStyles;
