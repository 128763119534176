import styled, { css } from 'styled-components'

export const DisclaimerLinkStyles = styled.section`
  .link-styles a {
    color: #c5e9fe;
    &:hover{
        color: ${props => props.theme.colors.vmfGreen2};
      }
    &:focus {
      color: ${props => props.theme.colors.vmfGreen2};
    }
  }

  .block-disclaimer {
    background: ${props => props.theme.colors.bedtime};
  }
`

const bannerTextStyles = (fontSizeRems, lineHeightRem) => css`
  font-size: ${fontSizeRems}rem;
  font-family: 'acumin-pro';
  line-height: ${lineHeightRem}rem;
  
  * {
    font-family: 'acumin-pro';
    font-size: ${fontSizeRems}rem;
    font-family: 'acumin-pro';
    line-height: ${lineHeightRem}rem;
  }
`

export default styled.section`
  .separator {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    height: 3px;
    position: relative;
  }
  .home-banner {
    border-bottom: none; // hide border for separator

    .banner__title {
      ${bannerTextStyles(2, 2.2)}

      @media (max-width: 860px) {
        ${bannerTextStyles(1.5, 1.9)}
      }

      @media (max-width: ${props => props.theme.breakpoints.tablet}) {
        ${bannerTextStyles(1.2, 1.29375)}
        padding-left: 1rem;
      }

      @media (max-width: 400px) {
        ${bannerTextStyles(1, 1.29375)}
        width: 100px;
      }
    }

    .banner__link {
      font-family: 'acumin-pro';

      * {
        font-family: 'acumin-pro';
      }

      @media (max-width: ${props => props.theme.breakpoints.tablet}) {
        flex-basis: 120px;
      }

    }    
  }
`