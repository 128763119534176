import styled from "styled-components";

const TextSectionv2Styles = styled.section`
  background: ${props => props.backgroundColor};
  min-height: 348px;

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  svg {
    width: 100%;
    position: relative;
  }

  li, ul {
    padding: 0;
    margin: 0;
  }

  .container {
    position: relative;
    color: ${props => props.textColor};
    padding: 1rem 1rem 4rem;
    margin: auto;
    max-width: 1440px;

    .text-content {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: space-between;

      & > * {
        justify-self: center;
        max-width: 476px;
      }

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        grid-template-columns: 1fr 1fr;
        padding: 2rem 1.5rem;
        align-items: center;
      } 
    }

    .primary-content {
      text-align: center;
      padding-bottom: 1rem;
      width: 100%;
      font-size: ${props => props.theme.type.paragraph.intro};

      p {
        font-weight: 400;
      }

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        text-align: left;
        padding: 0;
      }
    }

    .secondary-list {
      padding: 1rem 0;
      display: table;
      grid-row-start: 2;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        grid-column-start: 2;
        grid-row-start: 1;
        padding: 0;
      }

      li {
        font-size: ${props => props.theme.type.paragraph.intro};
        font-weight: 400;
      }
    }

    .text-section-button {
      max-width: 345px;
      width: 100%;
      margin: 0 auto;

      a {
        justify-content: center;
        color: ${props => props.buttonTextColor};
        background: ${props => props.buttonBackgroundColor};
        border: 1px solid ${props => props.buttonTextColor};
        border-radius: 3px;

        &:hover, &:active, &:focus {
          background: ${props => props.buttonTextColor};
          color: ${props => props.buttonBackgroundColor};
          border: 1px solid ${props => props.buttonBackgroundColor};
        }
      }
    }

    .disclaimer-text {
      font-size: ${props => props.theme.type.paragraph.xs};
      font-weight: 400;
      padding: 1rem 0;
      text-align: center;
      display: table;
      width: 100%;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        text-align: left;
        grid-column-start: 1;
        grid-row-start: 2;
      }
    }
  }
`;

export default TextSectionv2Styles;