import styled from 'styled-components';

export default styled.section`
  padding: 4rem ${props => props.theme.padding.inner} 5.25rem;
  background: ${props => props.theme.colors.blue10};

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: 5.3125rem ${props => props.theme.padding.inner} 7.625rem;
  }

  .inner {
    max-width: ${props => props.theme.maxWidths.containerSmall};
    margin: auto;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.container};
    }
  }

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.3125rem;

    h2 {
      margin: 0 0 0.6875rem;

      @media (max-width: ${props => props.theme.breakpoints.desktop}) {
        font-size: ${props => props.theme.type.h2Mobile};
        line-height: ${props => props.theme.lineHeights.h2Mobile};
      }
    }

    > * {
      @media (prefers-reduced-motion: no-preference) {
        .isAnimated& {
          opacity: 0;
          transform: translateY(10px);
          transition: all 0.4s ease-in-out;

          &:nth-child(2) {
            transition-delay: 0.2s;
          }
        }
      }

      .active& {
        opacity: 1;
        transform: none;
      }
    }
  }

  .review-widgets-wrapper{
    max-width: 900px;
    padding: 1.5rem 1.5rem 0.5rem;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    background-color: white;
    justify-content: space-around;
    @media (max-width: ${props => props.theme.breakpoints.desktop}) {
        padding-bottom: 1.5rem;
      }

    .consumer-wrapper{
      display: flex;
      flex-direction: column;
      height: auto;
      align-items: center;
      background-color: white;

      img {
        max-width: 300px;
        height: auto;
      }

      iframe {
        margin-top: -10px;
      }
    }

    .client-review-wrapper{
      padding-top: .5rem;
      padding-right: .5rem;
      .bf-dv{
        background-color: white !important;
      }
    }
  }

  .overview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justiy-content: center;
    color: ${props => props.theme.colors.vmfGreen2};

    p {
      color: ${props => props.theme.colors.grey1};
      margin: 0;

      &.type-h5 {
        font-weight: 800;
        margin-right: 0.4rem;
      }

      &.total-reviews {
        margin-left: 0.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          color: ${props => props.theme.colors.grey2};
          margin-left: 0.25rem;
          height: 1em;
        }
      }
    }

    .rating {
      svg {
        margin: 0;

        @media (min-width: ${props => props.theme.breakpoints.tablet}) {
          margin: 0 0.2rem;
        }
      }
    }
  }

  .testimonials {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin: 0;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      flex-direction: row;
    }

    li {
      margin: 1.125rem 0;
      padding: 0;
      overflow: hidden;

      @media (prefers-reduced-motion: no-preference) {
        .isAnimated& {
          opacity: 0;
          transform: scale(1.1);
          transition: all 0.4s ease-in-out;

          &:nth-child(1) {
            transition-delay: 0.4s;
          }

          &:nth-child(2) {
            transition-delay: 0.6s;
          }

          &:nth-child(3) {
            transition-delay: 0.8s;
          }
        }
      }

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        margin: 0 0.75rem;
        width: calc(33.33% - 0.75rem);

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .active& {
        opacity: 1;
        transform: none;
      }
    }

    .card {
      padding: 2.25rem 1.125rem 2.0625rem;
      text-align: center;
      box-shadow: ${props => props.theme.shadows.low};
      border-radius: 0;
      height: 100%;
      display: flex;
      flex-direction: column;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        padding: 2.625rem 2.125rem 1.9375rem;
      }

      .rating {
        height: 15px;
        margin-bottom: 1.5rem;
      }

      .content {
        flex-grow: 1;
      }
    }

    .rating {
      color: ${props => props.theme.colors.vmfGreen2};

      svg {
        margin: 0 0.6rem;
      }
    }
  }
`;
