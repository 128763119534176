import React from 'react';
import PropTypes from 'prop-types';
import DisclaimerStyles from './Disclaimer.styled';
import theme from '../../styles/theme';
import createClassList from '../../util/createClassList';
import InView from '../../components/Shared/InView';
import SimpleAnimation from '../../styles/Components/SimpleAnimation';

const Disclaimer = ({
  children,
  backgroundColor,
  textColor,
  background = theme.colors.darkBlue,
  appearance = 'light',
  width = 'wide',
  isAnimated = true,
  dark = '',
  className = '',
}) => {
  return (
    <InView>
      <DisclaimerStyles
        className={createClassList(className, 'block-disclaimer')}
        id='disclaimer'
        background={background}
        appearance={appearance}
        width={width}
        dark={dark}
        backgroundColor={backgroundColor}
        textColor={textColor}
      >
        <SimpleAnimation.div
          shouldAnimate={isAnimated}
          className='inner'
          slideDirection='up'
        >
          {children}
        </SimpleAnimation.div>
      </DisclaimerStyles>
    </InView>
  );
};

Disclaimer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default Disclaimer;
