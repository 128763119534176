import { useEffect, useState } from 'react';
import theme from '../styles/theme';

/**
 * Custom hook to determine if the current window width is considered mobile.
 *
 * @param {number} [size] - Optional breakpoint size to override the default desktop breakpoint.
 * @returns {Object} - An object containing a boolean `isMobile` indicating if the window width is less than the breakpoint.
 */
export default function useIsMobile(size) {
  const breakpoint = size || parseInt(theme.breakpoints.desktopMed, 10);
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' && window.innerWidth < breakpoint
  );

  useEffect(() => {
    /**
     * Event handler for window resize events.
     *
     * @param {Event} e - The resize event.
     */
    function handleWindowSizeChange(e) {
      const target = e.target;
      if (target.window.innerWidth < breakpoint) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, [breakpoint]);

  return { isMobile };
}
