import styled from 'styled-components';
import { getColors } from 'vmfcom-lib/utils';

const DisclaimerStyles = styled.section`
  padding: 3.1875rem ${props => props.theme.padding.inner};
  background: ${props => props.backgroundColor ? props.backgroundColor : props.background || props.theme.colors.darkBlue};
  
  .inner {
    margin: auto;
    max-width: ${props => props.theme.maxWidths.containerSmall};
    color: ${props => props.textColor ? `${props.textColor} !important` : (props.dark ? 'black !important' : '')};
        
    

    p {
      font-size: ${props => props.theme.type.paragraph.xs};
      line-height: ${props => props.theme.lineHeights.paragraph.xs};
      letter-spacing: ${props => props.theme.letterSpacing.paragraph};
      color: ${props => props.textColor ? `${props.textColor} !important` : (props.dark ? 'black !important' : '')};

      a{
        color: ${props => props.textColor ? `${props.textColor} !important` : (props.dark ? 'black !important' : '')};
      }
      
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props =>
        props.width === 'wide'
          ? props.theme.maxWidths.container
          : props.theme.maxWidths.post};
          
    }
  }
`;

export default DisclaimerStyles;
