import styled from 'styled-components';
import { getColors } from 'vmfcom-lib/utils';
import { getHexValuesInString, lightenColor } from '../../../util/colors';

const getTextAlign = alignContent => {
  switch (alignContent) {
    case 'Center Align':
      return 'center';
    case 'Right Align':
      return 'right';
    case 'Left Align':
    default:
      return 'left';
  }
};

export default styled.section`
  background: ${props =>
    getColors(props.$data.heroBackgroundColor).primary ||
    props.theme.colors.white};
  padding: 2.5rem 1.25rem 1.875rem;

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: 4rem 2rem;
  }

  .hero-section {
    display: flex;
    flex-direction: ${props =>
      props.$data.mediaPosition == 'Logo and Header on Left Side'
        ? 'column'
        : 'column-reverse'};
    text-align: center;
    max-width: 512px;
    margin: 0 auto;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      text-align: ${props =>
        getTextAlign(props.$data.heroContent.alignText) || 'center'};
      flex-direction: ${props =>
        props.$data.mediaPosition == 'Logo and Header on Left Side'
          ? 'row'
          : 'row-reverse'};
      gap: 5rem;
      max-width: 1224px;
    }
  }

  .hero-logo {
    max-width: 180px;
  }

  .hero-title {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: ${props =>
        getColors(props.$data.heroContent.headerTextColor).primary ||
        props.theme.colors.bedtime};
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 47px; /* 117.5% */
      letter-spacing: -1.25px;
      margin: 0;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        margin-top: 2px;
        margin-bottom: 2rem;
        font-size: 60px;
        line-height: 64px; /* 106.667% */
      }
    }
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      color: ${props =>
        getColors(props.$data.heroContent.introTextColor).primary ||
        props.theme.colors.grey1};
      line-height: 1.6875; /* 168.75% */
      margin-bottom: 2.25rem;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        font-size: 1.375rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  .intro-links-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.25rem;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      justify-content: left;
      margin-bottom: 1.75rem;
    }
  }

  .intro-links {
    .button-link {
      font-weight: 700;
      justify-content: center;
      width: 100%;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px; /* 118.75% */
        padding: 1rem 1.375rem;
        width: auto;
      }
    }

    .cta-text-link {
      font-size: 14px;
      font-style: normal;
      gap: 10px;
      line-height: 17px; /* 121.429% */

      .link {
        font-weight: 600;
        margin-right: 0;

        &:hover {
          color: ${props => {
            return lightenColor(
              getHexValuesInString(
                props.$data.heroContent.introLinks[
                  props.$data.heroContent.introLinks.length - 1
                ].buttonAndTextColors
              )[1],
              6
            );
          }};
        }
      }
    }
  }
`;
