import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import InView from '../../Shared/InView';
import Styles from './HeroSection.styles';
import Link from '../../Shared/Link';
import ButtonLink from '../../Shared/ButtonLink';
import { getHexValuesInString } from '../../../util/colors';
import createGTMClass from '../../../util/createGTMClass';
import useIsMobile from '../../../hooks/useIsMobile';
import useGTM from '../../../hooks/useGTM';

/**
 * HeroSection component for displaying the main hero area of the homepage
 *
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.$data - Data object containing hero section content
 * @param {Object} [props.$data.heroLogo] - Logo information for the hero section
 * @param {Object} [props.$data.heroContent] - Content for the hero section
 * @param {string} [props.$data.heroContent.header] - Header text for the hero section
 * @param {string} [props.$data.heroContent.intro] - Introductory text for the hero section
 * @param {Array} [props.$data.heroContent.introLinks] - Array of link objects for the hero section
 * @param {string} [props.$data.heroContent.headerTextColor] - Color for the header text
 * @returns {React.ReactElement} The rendered HeroSection component
 */

const HeroSection = ({ $data }) => {
  const { isMobile } = useIsMobile();
  const introLinks = $data.heroContent?.introLinks || [];
  const [ctaLink, setCtaLink] = useState(null);
  const [triggerGTMEvent] = useGTM();

  const gtmCategoryMap = {
    apply: 'loan_application',
    sign_in: 'account_sign_in',
  };

  const gtmLabelMap = {
    apply: 'apply_1',
  };

  const getGtmCategory = gtmEvent => gtmCategoryMap[gtmEvent] || gtmEvent;

  const getGtmLabel = gtmEvent => gtmLabelMap[gtmEvent] || gtmEvent;

  useEffect(() => {
    if (
      introLinks.length > 0 &&
      introLinks[introLinks.length - 1].displayLinkAs === 'Text Link'
    ) {
      setCtaLink(introLinks.pop()); // Pop the last element
    }
  }, [introLinks]);

  return (
    <InView>
      <Styles $data={$data}>
        <div className='hero-section'>
          <div className='hero-title'>
            {!isMobile && (
              <img
                src={$data?.heroLogo[0]?.colorLogo?.file?.url}
                alt={$data?.heroLogo[0]?.colorLogo?.description}
                className='hero-logo'
                width={`${
                  $data?.heroLogo[0]?.colorLogo?.file?.details?.image?.width ||
                  171
                }`}
                height={`${
                  $data?.heroLogo[0]?.colorLogo?.file?.details?.image?.height ||
                  40
                }`}
              />
            )}
            <ReactMarkdown source={$data?.heroContent?.header} />
          </div>
          <div className='hero-content'>
            <ReactMarkdown source={$data?.heroContent?.intro} />
            <div className='intro-links'>
              <div className='intro-links-container'>
                {introLinks.map((link, index) => (
                  <>
                    {link.displayLinkAs === 'Button' && (
                      <ButtonLink
                        key={`intro-link-${index}`}
                        to={link.link}
                        appearance='dark'
                        outline={index % 2 !== 0 ? true : false}
                        contentfulColors={
                          index % 2 === 0 ? link.buttonAndTextColors : null
                        }
                        isExternal={true}
                        newTab={true}
                        onClick={triggerGTMEvent({
                          name: 'vmf.cta_click',
                          location: 'Home Page',
                          category: getGtmCategory(link.gtmEvent),
                          action: 'text',
                          label: getGtmLabel(link.gtmEvent),
                        })}
                        className={createGTMClass(
                          link.gtmEvent,
                          'hero',
                          'button'
                        )}
                      >
                        {link.linkText}
                      </ButtonLink>
                    )}
                    {link.displayLinkAs === 'Text Link' && (
                      <Link
                        key={`intro-link-${index}`}
                        to={link.link}
                        color={
                          getHexValuesInString(link.buttonAndTextColors)[1]
                        }
                        isExternal={true}
                        newTab={true}
                        onClick={triggerGTMEvent({
                          name: 'vmf.cta_click',
                          location: 'Home Page',
                          category: getGtmCategory(link.gtmEvent),
                          action: 'text',
                          label: getGtmLabel(link.gtmEvent),
                        })}
                        className={createGTMClass(
                          link.gtmEvent,
                          'hero',
                          'text'
                        )}
                      >
                        {link.linkText}
                      </Link>
                    )}
                  </>
                ))}
              </div>
              <div className='intro-links-container cta-text-link'>
                {ctaLink && (
                  <>
                    {ctaLink.linkLabel &&
                      ctaLink.linkLayout === 'Label Before Link' &&
                      `${ctaLink.linkLabel}`}
                    <Link
                      to={ctaLink.link}
                      color={
                        getHexValuesInString(ctaLink.buttonAndTextColors)[1]
                      }
                      isExternal={true}
                      newTab={true}
                      onClick={triggerGTMEvent({
                        name: 'vmf.cta_click',
                        location: 'Home Page',
                        category: 'create_account',
                        action: 'text',
                        label: 'create_account',
                      })}
                      className={createGTMClass(
                        ctaLink.gtmEvent,
                        'hero',
                        'text'
                      )}
                    >
                      {ctaLink.linkText}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Styles>
    </InView>
  );
};

export default HeroSection;
