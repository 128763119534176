import styled from 'styled-components';

export default styled.section`
  padding: 3.375rem ${props => props.theme.padding.inner} 3.625rem;
  overflow: hidden;
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: 6.1875rem ${props => props.theme.padding.inner} 7.4375rem;
  }

  > .inner {
    margin: auto;
    max-width: ${props => props.theme.maxWidths.containerSmall};
    position: relative;
    
    &:after {
      content: "";
      background: ${props => props.theme.colors.claytonGreen};
      position: relative;
      height: 1px;
      width: 50%;
      left: 25%;
      bottom: -45px;
      display: flex;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        bottom: -65px;
        left: 37%;  
        width: 306.5px;          
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.blurbsThree};
    }

    > header {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        margin-bottom: 5rem;
      }

      h2 {
        margin-top: 0;
      }

      @media (prefers-reduced-motion: no-preference) {
        .isAnimated& {
          opacity: 0;
          transform: transformY(10px);
          transition: all 0.4s ease-in-out;
        }
      }

      .active& {
        opacity: 1;
        transform: none;
      }
    }
  }

  .columns {
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      flex-direction: row;

      > * {
        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-top: 0;
      }
    }

    @media (prefers-reduced-motion: no-preference) {
      .isAnimated& {
        > * {
          opacity: 0;
          transform: scale(1.1);
          transition: all 0.4s ease-in-out;

          &:nth-child(1) {
            transition-delay: 0.2s;
          }

          &:nth-child(2) {
            transition-delay: 0.4s;
          }

          &:nth-child(3) {
            transition-delay: 0.5s;
          }
        }
      }
    }

    &.active {
      > * {
        opacity: 1;
        transform: none;
      }
    }
  }

  .column {
    margin: 2rem 0;
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      width: 100%;
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      margin: 0 2rem;
      width: calc(33.33% - 2rem);
    }

    svg {
      margin-bottom: 1rem;
      height: 80px;
      width: auto;
    }

    h5 {
      margin: 0.5rem 0;
    }
  }
`;
