import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import PostsStyles from './Posts.styled';
import Post from '../Shared/Post';
import Link from '../Shared/Link';
import ButtonLink from '../Shared/ButtonLink';
import createClassList from '../../util/createClassList';

const configurations = {
  default: 'default',
  homeBuying: 'homeBuying',
};

const Posts = ({
  title = '',
  link = false,
  posts = [],
  isAnimated = true,
  isLeftAligned = false,
  configuration = configurations.default,
  className = '',
  marginBottomAdjustment = '',
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const classes = useMemo(
    () =>
      createClassList(
        className,
        'block-posts',
        isAnimated && 'isAnimated',
        inView && 'active'
      ),
    [className, isAnimated, inView]
  );

  return (
    <PostsStyles
      ref={ref}
      className={classes}
      isLeftAligned={isLeftAligned}
      configuration={configuration}
      configurations={configurations}
    >
      <div className='inner'>
        {(title || (link && link.to && link.title)) && (
          <header>
            {title && <h2>{title}</h2>}
            {link && posts.length <= 4 && <Link {...link}>{link.title}</Link>}
          </header>
        )}

        <ul className='posts'>
          {posts.map((props, i) => (
            <li key={i} style={{ animationDelay: `${(i % 16) * 0.2 + 0.2}s` }}>
              <Post {...props} />
            </li>
          ))}
        </ul>

        {link && posts.length > 4 && (
          <footer style={{ marginBottom: marginBottomAdjustment }}>
            <ButtonLink appearance='dark' size='large' {...link}>
              {link.title}
            </ButtonLink>
          </footer>
        )}
      </div>
    </PostsStyles>
  );
};

Posts.propTypes = {
  title: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      newTab: PropTypes.bool,
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
    PropTypes.bool,
  ]),
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      category: PropTypes.string,
    })
  ),
  isAnimated: PropTypes.bool,
  isLeftAligned: PropTypes.bool,
  configuration: PropTypes.oneOf(Object.keys(configurations)),
};

export default Posts;
