import styled from 'styled-components';

const LogoGridStyles = styled.section`
  padding: 1.5rem 1rem;
  background: ${props => props.background || props.theme.colors.blue10};

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3.375rem 1rem;
  }

  .inner {
    margin: auto;
    text-align: center;
    max-width: ${props => props.theme.maxWidths.heroOverlap};

    h2 {
      margin: 0;
      padding-bottom: 0.5rem;

      @media (prefers-reduced-motion: no-preference) {
        .isAnimated& {
          opacity: 0;
          transform: translateY(10px);
          transition: all 0.4s ease-in-out;
        }
      }

      .active& {
        opacity: 1;
        transform: none;
      }

      @media (min-width: ${props => props.theme.breakpoints.tablet}) {
        padding-bottom: 1rem;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.container};
    }
  }

  .logos, .claytonLogoContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -0.3rem;
    list-style: none;
    padding: 0;
    justify-content: center;

    li, .claytonLogo {
      box-sizing: border-box;
      margin: 0 0.3rem;

      @media (prefers-reduced-motion: no-preference) {
        .isAnimated& {
          opacity: 0;
        }

        .active& {
          animation: 0.2s ease-in-out scale-in forwards;
        }
      }
    }

    .claytonLogo {
      padding-top: 10px;
      width: calc(60% - 1.875rem);
      max-width: 200px;
      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        width: calc(20.285% - 1.875rem);
      }
    }

    li {
      width: calc(33% - .55rem);
      max-width: 170px;
      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        width: calc(14.285% - 1.875rem);
      }
    }

    picture {
      position: relative;
      display: block;
      width: 100%;
      height: 0;
      padding-top: 61%;

      img {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: auto;
        transform: translateY(-50%);
        opacity: 0.7;
        filter: grayscale(100%);
        transition: all 0.2s ease-in-out;

        &:hover,
        &:focus,
        &:active {
          filter: none;
          opacity: 1;
        }
      }
    }

    a {
      &:hover,
      &:active,
      &:focus {
        img {
          filter: grayscale(0%);
          opacity: 1;
        }
      }
    }
  }

  ${props => props.theme.animations.scaleIn};
`;

export default LogoGridStyles;
