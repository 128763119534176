import styled from 'styled-components';

const PostsStyles = styled.section`
  padding: 0 ${props => props.theme.padding.inner} 6.1875rem;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 0 ${props => props.theme.padding.inner} 9.125rem;
  }

  ${props => {
    const { configuration, configurations } = props;
    if (!configuration || configuration === configurations.default) {
      return `
        > header {
          flex-direction: row;
        }
      `;
    }

    if (configuration === configurations.homeBuying) {
      return `
        .inner header {
          h2 {
            margin: 0 auto;
          }
        }

        @media (max-width: ${props.theme.breakpoints.desktop}) {
          padding-bottom: 6rem;
          
          .inner header {
            flex-direction: column;
            align-items: flex-start !important;

            h2 {
              margin: 0 !important;
              margin-bottom: 3.25rem !important;
            }
          }

          ul li {
            padding-left: 0 !important;
          }
        }

        @media (max-width: ${props.theme.breakpoints.tablet}) {
          .inner header h2 {
            margin-bottom: 1rem !important;
          }
        }
        
      `;
    }
  }}

  > .inner {
    margin: auto;
    max-width: ${props => props.theme.maxWidths.containerSmall};

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.container};
    }

    > header {
      display: flex;
      align-items: flex-end;
      padding: 2.688rem 0;

      @media (prefers-reduced-motion: no-preference) {
        .isAnimated& {
          opacity: 0;
          transform: translateY(10px);
        }

        .active& {
          animation: 0.4s ease-in-out slide-in-up forwards;
        }
      }

      a {
        font-weight: 600;
        margin-bottom: 0.2em;

        @media (min-width: ${props => props.theme.breakpoints.desktop}) {
          margin: auto;
        }

        svg {
          transform: translateY(35%);
        }
      }

      h2 {
        margin: 0 0.5rem 0 0;
        font-size: ${props => props.theme.type.h2};
        line-height: ${props => props.theme.lineHeights.h2};
        letter-spacing: ${props => props.theme.letterSpacing.h2};
      }

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        padding: 4.375rem 0 2rem 0;
        flex-direction: column;
        align-items: center;

        h2 {
          margin-bottom: 0.5rem;
        }

        a {
          margin-top: 0;
        }
      }
    }
  }

  .posts {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: ${props =>
      props.isLeftAligned ? 'flex-start' : 'center'};
    margin: 0;

    .title {
      > a:hover {
        color: ${props => props.theme.colors.claytonBlue};
      }
    }

    > li {
      width: 100%;
      margin-bottom: 2.688rem;
      box-sizing: border-box;

      @media (prefers-reduced-motion: no-preference) {
        .isAnimated& {
          opacity: 0;
          transform: translateY(10px);
        }

        .active& {
          animation: 0.4s ease-in-out slide-in-up forwards;
        }
      }

      article {
        height: 100%;
      }
    }

    @media (min-width: ${props =>
        props.theme.breakpoints.tablet} and max-width: ${props =>
        props.theme.breakpoints.desktop}) {
      margin: -0.75rem;

      > li {
        width: calc(50% - 1.5rem);
        margin: 0.75rem;

        &:nth-child(n + 2):last-child {
          margin-right: auto;
        }
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      margin: -0.75rem;

      > li {
        width: calc(25% - 1.5rem);
        margin: 0.75rem;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    padding: 1rem 0 0;
    justify-content: flex-start;

    a {
      width: max-content;
    }

    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      justify-content: center;
      padding: 4rem 0 0;
    }
  }

  ${props => props.theme.animations.slideInUp};
  ${props => props.theme.animations.slideInUp};
`;

export default PostsStyles;
