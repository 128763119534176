import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import CTACardStyles from './CTACard.styled';
import ButtonLink from '../../components/Shared/ButtonLink';
import theme from '../../styles/theme';

const CTACard = ({
  children,
  link,
  secondaryLink,
  note,
  disclaimer,
  backgroundTop = theme.colors.white,
  backgroundBottom = theme.colors.bedtime,
  foreground = theme.colors.blue10,
  form = false,
  condensed = false,
  divider = true,
  flat = false,
  isAnimated = true,
  isSimple = false,
  animationThreshold = 0.5,
  borders = false,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: animationThreshold,
  });

  return (
    <CTACardStyles
      backgroundTop={backgroundTop}
      backgroundBottom={backgroundBottom}
      foreground={foreground}
      condensed={condensed}
      divider={divider}
      flat={flat}
      ref={ref}
      borders={borders}
      className={`block-cta_card ${isSimple ? 'block-cta_card--simple' : ''} ${
        isAnimated ? 'isAnimated' : ''
      } ${inView ? 'active' : ''}`}
    >
      <div className='inner'>
        <div className='primary'>{children}</div>
        {!isSimple && (
          <div className='secondary'>
            <div className='links'>
              {link && link.title && link.to && (
                <ButtonLink {...link}>{link.title}</ButtonLink>
              )}
              {secondaryLink && secondaryLink.title && secondaryLink.to && (
                <ButtonLink {...secondaryLink}>
                  {secondaryLink.title}
                </ButtonLink>
              )}
            </div>
            {note && <div className='note'>{note}</div>}
            {form && <div className='form'>{form}</div>}
          </div>
        )}
        {disclaimer && <div className='disclaimer'>{disclaimer}</div>}
      </div>
    </CTACardStyles>
  );
};

CTACard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  link: PropTypes.shape({
    to: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    title: PropTypes.string.isRequired,
  }),
  secondaryLink: PropTypes.shape({
    to: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    title: PropTypes.string.isRequired,
  }),
  note: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  backgroundTop: PropTypes.string,
  backgroundBottom: PropTypes.string,
  foreground: PropTypes.string,
  condensed: PropTypes.bool,
  divider: PropTypes.bool,
  flat: PropTypes.bool,
  isAnimated: PropTypes.bool,
};

export default CTACard;
