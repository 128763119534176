import React from 'react';
import TextSectionStyles from './TextSection.styled';
import ButtonLink from '../../Shared/ButtonLink';
import theme from '../../../styles/theme';
import TextSectionIcon from '../../Shared/Icons/TextSectionIcon';
import useIsMobile from '../../../hooks/useIsMobile';

/**
 * TextSection component for displaying the text section of the homepage
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} [props.primaryText=''] - The main text content displayed in the component.
 * @param {string} [props.buttonText=''] - The text to display inside the button.
 * @param {string} [props.buttonUrl=''] - The URL the button will navigate to when clicked.
 * @param {string} [props.disclaimerText=''] - Small print or disclaimer text shown below the main content.
 * @param {Array} [props.secondaryList=[]] - An array of items to be displayed as a secondary list.
 * @param {string} [props.backgroundColor=theme.colors.bedtime] - The background color of the component.
 * @param {string} [props.textColor=theme.colors.white] - The color of all text content.
 * @param {string} [props.buttonBackgroundColor=theme.colors.white] - The background color of the button.
 * @param {string} [props.buttonTextColor=theme.colors.claytonBlue] - The color of the text inside the button.
 * @param {string} [props.iconTopBackground=theme.colors.white] - The background color for the top portion of an icon.
 * @param {string} [props.iconBottomBackground=theme.colors.bedtime] - The background color for the bottom portion of an icon.
 * @returns {React.ReactElement} A rendered TextSection component
 */

const TextSection = ({
  primaryText = '',
  buttonText = '',
  buttonUrl = '',
  disclaimerText = '',
  secondaryList = [],
  backgroundColor = theme.colors.bedtime,
  textColor = theme.colors.white,
  buttonBackgroundColor = theme.colors.white,
  buttonTextColor = theme.colors.claytonBlue,
  iconTopBackground = theme.colors.white,
  iconBottomBackground = theme.colors.bedtime,
}) => {
  const { isMobile } = useIsMobile(parseInt(theme.breakpoints.desktop));

  return (
    <TextSectionStyles
      backgroundColor={backgroundColor}
      textColor={textColor}
      buttonBackgroundColor={buttonBackgroundColor}
      buttonTextColor={buttonTextColor}
    >
      {isMobile && (
        <TextSectionIcon
          backgroundTop={iconTopBackground}
          backgroundBottom={iconBottomBackground}
        />
      )}
      <div className='container'>
        <div className='text-content'>
          <p className='primary-content'>{primaryText}</p>
          {disclaimerText && (
            <div className='disclaimer-text'>{disclaimerText}</div>
          )}
          {secondaryList && (
            <ul className='secondary-list'>
              {secondaryList.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
        {buttonText && buttonUrl && (
          <div className='text-section-button'>
            <ButtonLink title={buttonText} to={buttonUrl} size={'large'}>
              {buttonText}
            </ButtonLink>
          </div>
        )}
      </div>
    </TextSectionStyles>
  );
};
export default TextSection;
