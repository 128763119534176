import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import CTASimple from '../../components/CTASimple/CTASimple';
import theme from '../../styles/theme';
import Accordion from '../../components/Accordion/Accordion';
import LogoGrid from '../../components/LogoGrid/LogoGrid';
import Posts from '../../components/Posts/Posts';
import HeroSection from '../../components/Home/HeroSection/HeroSection';
import CTACard from '../../components/CTACard/CTACard';
import Testimonials from '../../components/Testimonials/Testimonials';
import BlurbsThree from '../../components/BlurbsThree/BlurbsThree';
import Loans from '../../components/Loans/Loans';
import Error from '../../components/Error/Error';
import { slugByLabel } from '../../util/faqCategories';
import useGTM from '../../hooks/useGTM';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import ReactMarkdown from 'react-markdown';
import Styles, { DisclaimerLinkStyles } from './Home.styled';
import { customLink } from '../../plugins/remark/renderers';
import createGTMClass from '../../util/createGTMClass';
import Banner from '../../components/Contentful/Banner/Banner';
import { getHexValuesInString } from '../../util/colors';
import { loadModel } from 'vmfcom-lib/utils';
import { getDPC, getBlogPosts } from '../../services/contentful';
import ProcessLoanIcon from '../../components/Shared/Icons/ProcessLoanIcon';
import ApplicationReviewIcon from '../../components/Shared/Icons/ApplicationReviewIcon';
import LoanApplyIcon from '../../components/Shared/Icons/LoanApplyIcon';
import TextSection from '../../components/Home/TextSection/TextSection';

const faqGTMLabels = {
  'how-we-calculate-your-payment-and-when-payments-are-due': 'payment_faq',
  'what-is-simple-interest': 'interest_faq',
  'requesting-loan-payoff-quote': 'payoff_quote_faq',
  'is-there-a-penalty-for-early-loan-payoff': 'early_payoff_faq',
};

const Home = ({ data, blogData, error, isLoading }) => {
  if (error) {
    console.error(error);
    return (
      <Layout>
        <Error />
      </Layout>
    );
  }

  const entry = loadModel(data, 'VMFCom', 'DynamicPageContent');

  if (isLoading) return null;

  const [triggerGTMEvent] = useGTM();
  const faqs =
    entry?.faqPod &&
    entry?.faqPod.map(
      ({
        faqQuestion: title,
        faqSummaryAnswer: answer,
        slug,
        faqCategory,
        ...rest
      }) => ({
        title,
        answer,
        showReadMore:
          rest.faqAnswerContent1 ||
          rest.faqAnswerContent2 ||
          rest.faqAnswerVideos,
        slug: slug.replace(
          'resources/faqs/',
          `resources/faqs/${slugByLabel(faqCategory?.[0])}/`
        ),
        onExpand: triggerGTMEvent({
          name: 'vmf.cta_click',
          location: 'Home Page',
          category: 'frequently_asked',
          action: 'summary',
          label:
            faqGTMLabels[
              slug.replace('/resources/faqs/', '').replace(/\/$/, '')
            ] ||
            slug
              .replace('/resources/faqs/', '')
              .replace(/-/g, '_')
              .replace(/\/$/, ''),
        }),
        onReadMore: triggerGTMEvent({
          name: 'vmf.cta_click',
          location: 'Home Page',
          category: 'frequently_asked',
          action: 'text',
          label:
            faqGTMLabels[
              slug.replace('/resources/faqs/', '').replace(/\/$/, '')
            ] ||
            slug
              .replace('/resources/faqs/', '')
              .replace('-', '_')
              .replace(/\/$/, ''),
        }),
      })
    );

  const posts =
    blogData &&
    blogData.map(
      ({ Title: title, contentTag, category, slug, featuredMedia }) => ({
        title,
        category,
        contentTag,
        thumbnail: `${
          featuredMedia?.media?.file?.url || featuredMedia?.thumbnail?.file?.url
        }?w=300`,
        mediaType: featuredMedia?.mediaType,
        slug,
      })
    );

  const testimonials =
    entry?.customerReviewPod &&
    entry.customerReviewPod.map(
      ({
        ratingStars: rating,
        customerName: author,
        reviewDate: date,
        review,
      }) => ({
        rating,
        author,
        date,
        review,
      })
    );

  return (
    <Layout isLoading={isLoading}>
      <SEO title={entry?.titleTag} description={entry?.metaDescription} />
      <Styles>
        {entry?.heroSection?.heroBanner[0] && (
          <>
            <Banner
              imageAnchorPoint={
                entry?.heroSection?.heroBanner[0]?.imageAnchorPoint
              }
              borderColor={
                getHexValuesInString(
                  entry?.heroSection?.heroBanner[0]?.bannerBorderColor
                )[0]
              }
              link={entry?.heroSection?.heroBanner[0]?.bannerLink}
              title={entry?.heroSection?.heroBanner[0]?.bannerTitle}
              image={{
                src: entry?.heroSection?.heroBanner[0]?.desktopBannerImage
                  ?.media?.file?.url,
                alt: entry?.heroSection?.heroBanner[0]?.desktopBannerImage
                  ?.description,
                sources: [
                  {
                    media: `(min-width: ${theme.breakpoints.desktop})`,
                    params: '?w=626',
                    srcSet:
                      entry?.heroSection?.heroBanner[0]?.desktopBannerImage
                        ?.media?.file?.url,
                  },
                  {
                    media: `(min-width: ${theme.breakpoints.tablet})`,
                    params: '?w=512',
                    srcSet:
                      entry?.heroSection?.heroBanner[0]?.tabletBannerImage
                        ?.media?.file?.url,
                  },
                  {
                    media: `(max-width: ${theme.breakpoints.tablet})`,
                    params: '?w=300',
                    srcSet:
                      entry?.heroSection?.heroBanner[0]?.mobileBannerImage
                        ?.media?.file?.url,
                  },
                ],
              }}
              fadeImageToBackground={false}
              backgroundColor={
                getHexValuesInString(
                  entry?.heroSection?.heroBanner[0]
                    ?.titleBackgroundAndTextColors
                )[0]
              }
              textColor={
                getHexValuesInString(
                  entry?.heroSection?.heroBanner[0]
                    ?.titleBackgroundAndTextColors
                )[1]
              }
              className='home-banner'
            />
            <div className='separator'></div>
          </>
        )}

        {entry?.heroSection && <HeroSection $data={entry.heroSection} />}

        <TextSection
          primaryText='We offer a wide range of finance programs for manufactured and mobile homes* loans - perfect credit to less-than-perfect credit.'
          disclaimerText='*All loans are subject to credit approval'
          secondaryList={[
            'First-time Homebuyers',
            'Home Only Loans',
            'Home and Land Loans',
            'Move-In Ready Home Loans',
          ]}
          buttonText='See Our Home Loans'
          buttonUrl='/home-loans/'
        />

        <BlurbsThree
          title='Here’s a 3 step look at how it all works.'
          borders={true}
          columnOne={
            <>
              <LoanApplyIcon />
              <h5>Apply for a Loan</h5>
              <p>
                To apply for a loan to finance the purchase of your dream home,
                fill out our easy online application, and we’ll connect you with
                a licensed loan specialist.
              </p>
            </>
          }
          columnTwo={
            <>
              <ApplicationReviewIcon />
              <h5>Application Review</h5>
              <p>
                A licensed loan specialist will review your application and
                contact you to obtain more information in order to provide you
                with a loan decision.
              </p>
            </>
          }
          columnThree={
            <>
              <ProcessLoanIcon />
              <h5>Process Loan</h5>
              <p>
                We will walk you through the process of collecting any
                documentation we may need from you in order to process your loan
                application as well as explain the credit requirements that
                apply for your selected loan program.
              </p>
            </>
          }
        />

        <Loans
          mobileTopPadding='1rem'
          tabletTopPadding='1rem'
          desktopTopPadding='1rem'
          link={{
            to: '/payment-options/',
            title: 'Learn about our payment options',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_evaluation',
              action: 'text',
              label: 'payment_options',
            }),
          }}
          viewAllLink={{
            to: '/home-loans/',
            title: 'View all',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_evaluation',
              action: 'button',
              label: 'mortgage_loan',
            }),
          }}
        />

        <LogoGrid background={theme.colors.blue10} />

        <CTACard
          backgroundTop={theme.colors.blue10}
          backgroundBottom={theme.colors.claytonBlue}
          foreground={theme.colors.white}
          link={{
            to: entry?.contentCTAs?.[0]?.firstCTAButtonUrl || '/home-buying/',
            title: entry?.contentCTAs?.[0].firstCTAButtonText || 'Learn more',
            outline: true,
            appearance: 'dark',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'home_decision',
              action: 'button',
              label: 'pre_owned',
            }),
          }}
          secondaryLink={{
            to:
              entry?.contentCTAs?.[0]?.secondCTAButtonUrl ||
              '/applynow/welcome/',
            title: entry?.contentCTAs?.[0].secondCTAButtonText || 'Apply Now',
            appearance: 'dark',
            newTab: true,
            isExternal: true,
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_application',
              action: 'button',
              label: 'apply_2',
            }),
            className: createGTMClass('apply', 'cta', 'button'),
          }}
          condensed={true}
          divider={false}
          flat={false}
          borders={true}
        >
          <h3 className='type-h4 no-margin-bottom'>
            {entry?.contentCTAs?.[0]?.ctaHeader ||
              'Looking to Buy a Pre-owned Home?'}
          </h3>
          <p>
            {entry?.contentCTAs?.[0]?.ctaIntro ||
              'We also offer Direct Lending loans for owner-to-owner transactions and financing for pre-owned homes purchased from VMF Homes.'}
          </p>
        </CTACard>

        <CTASimple
          title={
            entry?.contentCtAs?.[1]?.ctaHeader || 'Mortgage Payment Calculator'
          }
          link={{
            title:
              entry?.contentCtAs?.[1]?.firstCtaButtonText || 'View Calculator',
            to:
              entry?.contentCtAs?.[1]?.firstCtaButtonUrl ||
              'calculators/mortgage-calculator/',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_evaluation',
              action: 'button',
              label: 'estimate_payment',
            }),
          }}
          condensed={true}
          bordersBottom={true}
        >
          <p>
            {entry?.contentCtAs?.[1]?.ctaIntro ||
              'Trying to plan what your payment may look like? Are you renting and want to compare your current rent to an estimated mortgage? We want to give you the tools to plan ahead by providing our Monthly Payment Calculator.'}
          </p>
        </CTASimple>

        <Testimonials testimonials={testimonials} />

        <Accordion
          items={faqs}
          link={{
            url: '/resources/faqs/',
            label: 'View all',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'frequently_asked',
              action: 'text',
              label: 'all_faqs',
            }),
          }}
        />

        <Posts
          title='Tips & Tools'
          link={{
            title: 'View all',
            to: '/resources/blog/',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_education',
              action: 'text',
              label: 'tips_tools',
            }),
          }}
          posts={posts}
        />
        <CTASimple
          title={entry?.preFooterCta?.ctaHeader || 'Dreaming of Homeownership?'}
          link={{
            to: entry?.preFooterCta?.firstCTAButtonUrl || '/applynow/welcome/',
            title: entry?.preFooterCta?.firstCTAButtonText || 'Apply Now',
            isExternal: true,
            newTab: true,
            size: 'large',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_application',
              action: 'button',
              label: 'apply_3',
            }),
            className: createGTMClass('apply', 'prefoot_cta', 'button'),
          }}
          background={theme.colors.claytonBlue}
        ></CTASimple>

        <DisclaimerLinkStyles>
          <Disclaimer>
            <ReactMarkdown
              className='link-styles'
              source={entry?.disclaimer}
              renderers={{
                link: customLink(
                  { target: '_blank' },
                  'http://www.nmlsconsumeraccess.org/'
                ),
              }}
            />
          </Disclaimer>
        </DisclaimerLinkStyles>
      </Styles>
    </Layout>
  );
};

Home.getInitialProps = async ({}) => {
  if (typeof window === 'undefined') {
    try {
      const dpcData = await getDPC('/');
      const blogData = await getBlogPosts(4);
      return { data: dpcData, blogData };
    } catch (err) {
      return {
        err,
      };
    }
  } else {
    window.location.reload();
  }
};

Home.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.shape({}),
  error: PropTypes.object,
};

export default Home;
